import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "./components/Header"; // Adjust the import path as necessary
import RecordList from "./components/RecordList"; // Adjust the import path as necessary
import RecordDetails from "./components/RecordDetails"; // Adjust the import path as necessary
import { Box, Container, CircularProgress } from "@mui/material";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const App = () => {
  const [records, setRecords] = useState([]);
  const [filter, setFilter] = useState("all");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchRecords();
  }, [filter]);

  const fetchRecords = async () => {
    setLoading(true); // Start loading indicator
    try {
      let url = "https://winwithsunny.symloop.com/api/records";
      if (filter === "accepted") {
        url = "https://winwithsunny.symloop.com/api/records/accepted";
      } else if (filter === "refused") {
        url = "https://winwithsunny.symloop.com/api/records/refused";
      }

      const response = await axios.get(url);
      setRecords(response.data);
    } catch (error) {
      console.error("Error fetching records", error);
    } finally {
      setLoading(false); // Stop loading indicator
    }
  };

  const handleAccept = async (id) => {
    try {
      await axios.put(`https://winwithsunny.symloop.com/api/records/${id}/accept`);
      fetchRecords();
    } catch (error) {
      console.error("Error accepting record", error);
    }
  };

  const handleRefuse = async (id) => {
    try {
      await axios.put(`https://winwithsunny.symloop.com/api/records/${id}/refuse`);
      fetchRecords();
    } catch (error) {
      console.error("Error refusing record", error);
    }
  };

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Header
                onShowAll={() => setFilter("all")}
                onShowAccepted={() => setFilter("accepted")}
                onShowRefused={() => setFilter("refused")}
              />
              <Container sx={{ marginTop: 4 }}>
                <Box sx={{ marginTop: 2 }}>
                  {loading ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  ) : (
                    <RecordList
                      records={records}
                      onAccept={handleAccept}
                      onRefuse={handleRefuse}
                    />
                  )}
                </Box>
              </Container>
            </>
          }
        />
        <Route path="/details" element={<RecordDetails />} />
      </Routes>
    </Router>
  );
};

export default App;
