import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Container,
  Button,
} from "@mui/material";
import * as XLSX from "xlsx";
import { useNavigate } from "react-router-dom";

const RecordDetails = () => {
  const [acceptedRecords, setAcceptedRecords] = useState([]);
  const [refusedRecords, setRefusedRecords] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchAcceptedRecords();
    fetchRefusedRecords();
  }, []);

  const fetchAcceptedRecords = async () => {
    try {
      const response = await axios.get(
        "https://winwithsunny.symloop.com/api/records/accepted"
      );
      setAcceptedRecords(response.data);
    } catch (error) {
      console.error("Error fetching accepted records", error);
    }
  };

  const fetchRefusedRecords = async () => {
    try {
      const response = await axios.get(
        "https://winwithsunny.symloop.com/api/records/refused"
      );
      setRefusedRecords(response.data);
    } catch (error) {
      console.error("Error fetching refused records", error);
    }
  };

  const downloadExcel = (data, fileName) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Records");
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <Container>
      <Box sx={{ textAlign: "left", marginY: 2 }}>
        <Button variant="contained" onClick={handleBack} sx={{ background:"orange"}}>
          Go Back
        </Button>
      </Box>

      <Typography variant="h4" sx={{ marginY: 4, textAlign: "center" }}>
        Record Details
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <Card sx={{ textAlign: "center", padding: 2 }}>
            <CardContent>
              <Typography variant="h5">Accepted Records</Typography>
              <Typography variant="h4" color="primary"  sx={{color:"orange" }}>
                {acceptedRecords.length}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => downloadExcel(acceptedRecords, "Accepted_Records")}
                sx={{ marginTop: 2,background:"orange" }}
              >
                Download Excel
              </Button>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Card sx={{ textAlign: "center", padding: 2 }}>
            <CardContent>
              <Typography variant="h5">Refused Records</Typography>
              <Typography variant="h4" color="error">
                {refusedRecords.length}
              </Typography>
              <Button
                variant="contained"
                color="error"
                onClick={() => downloadExcel(refusedRecords, "Refused_Records")}
                sx={{ marginTop: 2 }}
              >
                Download Excel
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Typography variant="h6" sx={{ marginY: 4, marginTop: 6 }}>
        Accepted Records:
      </Typography>
      <Grid container spacing={2}>
        {acceptedRecords.map((record) => (
          <Grid item xs={12} sm={6} md={4} key={record.id}>
            <Card sx={{ maxWidth: 345 }}>
              <CardMedia
                component="img"
                height="140"
                image={`https://winwithsunny.symloop.com/public/${record.image}`} // Update with the correct path to the image
                alt="Accepted Record"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {record.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {record.email}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Typography variant="h6" sx={{ marginY: 4, marginTop: 6 }}>
        Refused Records:
      </Typography>
      <Grid container spacing={2}>
        {refusedRecords.map((record) => (
          <Grid item xs={12} sm={6} md={4} key={record.id}>
            <Card sx={{ maxWidth: 345 }}>
              <CardMedia
                component="img"
                height="140"
                image={`https://winwithsunny.symloop.com/public/${record.image}`} // Update with the correct path to the image
                alt="Refused Record"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {record.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {record.email}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default RecordDetails;
