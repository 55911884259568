import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  Container,
} from "@mui/material";

const App = () => {
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    fetchRecords();
  }, []);

  const fetchRecords = async () => {
    setLoading(true);
    try {
      const response = await axios.get("https://winwithsunny.symloop.com/api/records");
      setRecords(response.data);
    } catch (error) {
      console.error("Error fetching records", error);
    } finally {
      setLoading(false);
    }
  };

  const handleAccept = async (id) => {
    try {
      await axios.put(`https://winwithsunny.symloop.com/api/records/${id}/accept`);
      fetchRecords();
    } catch (error) {
      console.error("Error accepting record", error);
    }
  };

  const handleRefuse = async (id) => {
    try {
      await axios.put(`https://winwithsunny.symloop.com/api/records/${id}/refuse`);
      fetchRecords();
    } catch (error) {
      console.error("Error refusing record", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://winwithsunny.symloop.com/api/records/${id}`);
      fetchRecords(); // Refresh records after deleting
    } catch (error) {
      console.error("Error deleting record", error);
    }
  };

  const handleClickOpen = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  return (
    <Container>
      <h1>Record Management</h1>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ padding: 2 }}>
          <List>
            {records.map((record) => (
              <ListItem key={record.id} sx={{ display: "flex", justifyContent: "space-between" }}>
                <ListItemText primary={record.name} secondary={record.email} />
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ mr: 1 }}
                    onClick={() => handleClickOpen(record.image)} // Show image on click
                  >
                    Show Image
                  </Button>
                  <Button
                    variant="contained"
                    color="success"
                    sx={{ mr: 1 }}
                    onClick={() => handleAccept(record.id)}
                  >
                    Accept
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    sx={{ mr: 1 }}
                    onClick={() => handleRefuse(record.id)}
                  >
                    Refuse
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleDelete(record.id)} // Delete record on click
                  >
                    Delete
                  </Button>
                </Box>
              </ListItem>
            ))}
          </List>

          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Record Image</DialogTitle>
            <DialogContent>
              {selectedImage ? (
                <img
                  src={`https://winwithsunny.symloop.com/public/${selectedImage}`} // Update the image URL as necessary
                  alt="Record"
                  style={{ width: "100%", height: "auto", borderRadius: "8px" }}
                />
              ) : (
                <p>No Image Available</p>
              )}
            </DialogContent>
          </Dialog>
        </Box>
      )}
    </Container>
  );
};

export default App;
