import React from "react";
import { Button, AppBar, Toolbar, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const Header = ({ onShowAll, onShowAccepted, onShowRefused }) => {
  return (
    <AppBar position="static" sx={{ width: "100%", color: "white", background: "orange" }}>
      <Toolbar>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Record Management
        </Typography>
        <Button color="inherit" onClick={onShowAll}>
          Show All
        </Button>
        <Button color="inherit" onClick={onShowAccepted}>
          Show Accepted
        </Button>
        <Button color="inherit" onClick={onShowRefused}>
          Show Refused
        </Button>
        <Button color="inherit" component={Link} to="/details">
          View Details
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
